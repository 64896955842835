<template>
    <div class="pbottom-large">
        <PostIt>This view will be redesigned soon.</PostIt>
        <IntranetRenaming ref="IntranetRenaming"/>
        <LogoUpload ref="LogoUpload"/>
        <StickyFooter>
            <div class="container">
                <Button
                    :busy="busy"
                    @click="save()"
                >
                    {{ $t("save") }}
                </Button>
            </div>
        </StickyFooter>
    </div>
</template>

<script>
import IntranetRenaming from "./IntranetRenaming.vue";
import LogoUpload from "./LogoUpload";
import Button from "@web/components/Button.vue";
import StickyFooter from "@web/components/StickyFooter.vue";
import {
    StorageLimitReachedError,
} from "@web/exceptions";

export default {
    name: "GeneralSettings",
    components: {
        IntranetRenaming,
        LogoUpload,
        Button,
        StickyFooter,
    },
    data() {
        return {
            busy: false,
        };
    },
    methods: {
        save() {
            this.busy = true;
            Promise.all(Object.values(this.$refs).map(ref => ref.save()))
                .then((_) => {
                    this.busy = false;
                    this.$notify({
                        group: "app",
                        type: "success",
                        text: this.$t("general_settings_save_success_message"),
                    });
                }).catch(e => {
                    if (e instanceof StorageLimitReachedError) {
                        this.$notify({
                            group: "app",
                            type: "error",
                            text: this.$t("administration_storage_limit_reached")
                        });
                    } else {
                        this.$notify({
                            group: "app",
                            type: "error",
                            text: `${this.$t("general_settings_save_error_message")} - ${e.message}`,
                        });
                    }
                    this.busy = false;
                });
        }
    }

};
</script>

<style lang="scss" scoped>
.divider {
    margin-bottom: 4rem;
}
</style>
