<template>
    <transition
        name="moveInFromBottom"
        appear
    >
        <div class="sticky-footer">
            <slot ></slot>
        </div>
    </transition>
</template>

<script>
export default {
    name: "StickyFooter",
};
</script>

<style lang="scss" scoped>
    .sticky-footer {
        position: sticky;
        width: calc(100% + 2rem);
        bottom: 0;
        left: -2rem;
        right: 0;
        padding: 0.75rem 2rem;
        margin: 0 -2rem;
        background: var(--background, #{$white});
        /* outline: 1px solid red; */

        &::v-deep {

            .container {
                padding-left:0;
                padding-right: 0;
                // reposition hack to have the border properly indented, ignoring the padding of the parent
                &:before {
                    content: "";
                    position: absolute;
                    width: calc(100% - 3rem);
                    height: 0;
                    left: 1.5rem;
                    top: -1px;
                    border-top: 1px solid var(--lowest-contrast, #{$off-white});
                }
            }
        }
    }
</style>

<docs>
```vue
<sticky-footer/>
```
</docs>
