<template>
    <div class="pbottom-medium">
        <h2>{{ $t("change_intranet_name") }}</h2>
        <div
            class="form-group"
            style="max-width: 400px;"
        >
            <div class="pbottom-medium">
                <input
                    v-model="displayName"
                    type="text"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";
import {
    mapActions,
    mapState
} from "vuex";
import { UPDATE_INTRANET } from "@web/store/intranet/actions";

export default Vue.extend({
    name: "IntranetRenaming",
    comments: {},
    data() {
        return {
            displayName: "",
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, [INTRANET]),
    },
    methods: {
        ...mapActions({ updateIntranet: INTRANET_MODULE_NAME + UPDATE_INTRANET }),
        async save() {
            const { displayName } = this;
            if (displayName === this.intranet.displayName) {
                return;
            }
            if (displayName.length < 3) {
                throw Error(this.$t("change_intranet_name_to_short"));
            }
            return this.updateIntranet({ displayName });
        }
    },
    mounted() {
        this.displayName = this.intranet.displayName;
    }
});
</script>

<style scoped>

</style>
