<template>
    <div class="pbottom-medium">
        <h2>{{ $t("upload_logo") }}</h2>
        <div class="grid wrap logo-grid">
            <div class="half">
                <div
                    class="card depth-1 padding-medium "
                    @click="$refs.file.click()"
                >
                    <div class="logo-preview">
                        <img
                            v-if="previewUrl || logoUrl"
                            :src="previewUrl || logoUrl"
                        />
                        <img
                            v-if="previewUrl"
                            ref="colorpicker"
                            :src="previewUrl"
                            style="display:none"
                        />
                        <div>
                            <input
                                id="files"
                                ref="file"
                                class="hidden"
                                type="file"
                                name="file"
                                accept="image/jpeg, image/png"
                                :multiple="false"
                                @change="handleFileChoose"
                            />
                        </div>
                    </div>
                </div>
                <div class="replace-logo pbottom-medium">
                    <a
                        class="button link replace-logo-link"
                        @click="$refs.file.click()"
                    >{{ $t("replace_logo") }}</a>
                </div>
                <p class="meta centered">
                    {{ $t("logo_description") }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import {
    INTRANET,
    LOGO_URL
} from "@web/store/intranet/getters";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import {
    intranetService,
    themeService
} from "@web/services";
import { RELOAD_CURRENT_INTRANET } from "@web/store/intranet/actions";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";
import { THEMING_MODULE_NAME } from "@web/store/theming/theming";
import { COLORS } from "@web/store/theming/getters";

const oneMBinBytes = 1048576;

export default Vue.extend({
    name: "LogoUpload",
    components: {
    },
    data() {
        return {
            previewUrl: undefined,
            file: undefined,
        };
    },
    computed: {
        intranet() {
            return this.$store.getters[INTRANET_MODULE_NAME + INTRANET];
        },
        logoUrl() {
            return this.$store.getters[INTRANET_MODULE_NAME + LOGO_URL];
        },
        currentUser() {
            return this.$store.getters[AUTH_MODULE_NAME + CURRENT_USER];
        },
        themeColors() {
            return this.$store.getters[THEMING_MODULE_NAME + COLORS];
        },

    },
    methods: {
        ...mapActions({ reloadIntranet: INTRANET_MODULE_NAME + RELOAD_CURRENT_INTRANET }),

        handleFileChoose(event) {
            if (event !== null) {
                const selectedFile = event.target.files[0];
                if (selectedFile.size > oneMBinBytes) {
                    this.$notify({
                        group: "app",
                        type: "error",
                        text: this.$t("error_file_over_10mb")
                    });
                    (this.$refs.file).value = "";
                    this._clearLogoUpload();
                    return;
                }
                this.file = selectedFile;
                this.previewUrl = URL.createObjectURL(this.file);
            }
        },
        async uploadToStorage() {
            if (this.file !== undefined) {
                try {
                    const filePath = await themeService.uploadNewLogo(
                        this.intranet.uid,
                        this.currentUser.uid,
                        this.file
                    );
                    analytics.log(getGlobalConfiguration().analytics_event_name_logo_updated);
                    localStorage.setItem("showAutoTheming", "true");
                    return filePath;
                } catch (e) {
                    throw e;
                }
            }
        },
        async save() {
            if (this.file === undefined) {
                return;
            }
            const filePath = await this.uploadToStorage();
            if (filePath == null) {
                throw Error($t("error_logo_upload"));
            }
            await themeService.saveLogo(this.intranet.uid, filePath);

            this.reloadIntranet();
            this._clearLogoUpload();
            await intranetService.clearIntranetApiCache();
        },
        _clearLogoUpload() {
            this.file = undefined;
            this.previewUrl = undefined;
        }
    },
});
</script>

<style lang="scss" scoped>
    .logo-grid {
        .logo-preview {
            display: flex;
            text-align: left;
            justify-content: center;
            align-items: flex-start;
            height: 50px;
            width: 280px;
            margin: 40px 0;

            cursor: pointer;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .replace-logo {
            text-align: center;
        }

    }

    .swatches {
        position: relative;
        max-width: 200px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        user-select: none;

        .swatch-circle {
            width: 30px;
            height: 30px;
            margin-right: 8px;
            border-radius: 100%;
        }

    }

</style>
